import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"


import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing} from "../constants"

import useToggle from "../components/useToggle"

const TicketsBox = ({text}) => {
  const [ticketOpen, toggleTicket] = useToggle();



  return (
    <div css={css`
      width: 190px;
      margin: 0 0 ${spacing.small} 0;
      
      background-color: ${color.light};
      color: ${color.primary};
      border-bottom: ${spacing.xs} ${text == null ? "#EFEFEF" : color.secondary } solid;
      &:hover {
        background-color: #fafafa;
      }
    `}
    >
      <div css={css`
        cursor: pointer; 
        padding: calc(1.5*${spacing.small}) ${spacing.small} ${spacing.small} ${spacing.small};
      `} 
        onClick={text != null ? toggleTicket : ''}>
        <h4 css={css`margin: 0;`}>Tickets</h4>
      </div>
      <div css={css`
        background: #fff;
        overflow: hidden;
        max-height: ${ticketOpen ? '300px' : '0'};
        transition: max-height .5s ease-in-out;
      `}>
        <p css={css`
          border-top: ${spacing.xs} #EFEFEF solid;
          padding: ${spacing.small};
          line-height: 1.5;
          margin: 0;

          p {
            margin: 0;
          }
        `}
        dangerouslySetInnerHTML={{ __html: text }} 
        >
          
        </p>
        <p css={css`
          padding: ${spacing.small};
          line-height: 1.5;
          margin: 0;
          text-align: center;

          p {
            margin: 0;
          }
        `}
        >
          <a href="#ticket-kauf">Ticket-Kauf</a>
          </p>
        
      </div>
    </div>
  )
}

export default TicketsBox
